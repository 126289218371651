import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import {
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
    Typography,
} from '@material-ui/core'
import {withRouter} from 'react-router'
import {pagePatients, pages2} from './tileData'
import {Link} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import Ads from '../comp/Ads';
import PanelDivider from '../comp/PanelDivider';
import config from '../config';
import {
    clearContextPatient, closePublicPatientsGroups, getHyphenedLang, getPatientTypeFromPath,
    getURLSearchParams,
    intersect, isPrivatePatientsEnable,
    isShowGroups,
    patientsGroupsOpen, patientsRouteCompare,
} from "../utils";
import mainStyles from '../styles/mainStyles';
import InfoIcon from '@material-ui/icons/Info';
import LinkedinIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LanguageIcon from '@material-ui/icons/Language';
import HelpIcon from '@material-ui/icons/Help';
import type {ActivePatient} from "../const";
import {withApollo} from "@apollo/client/react/hoc";
import ConfirmDialog from "../comp/patient/ConfirmDialog";
import ManualDialog from "../comp/patient/ManualDialog";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import GlossaryDialog from "../comp/GlossaryDialog";
import Hyphenated from 'react-hyphen';


const styles = theme => ({
    ...mainStyles(theme),
    paper: {
        width: 240,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
    toolbarIe11: {
        display: 'flex',
    },
    toolbar: {
        ...theme.mixins.toolbar,
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    anchor: {
        color: theme.palette.text.secondary,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class AppDrawer extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            regOpen: true,
            nosologiesOpen: true,

            // TODO: for ConfirmDialog
            openConfirmDialog: false,
            openManualDialog: false,
            openGlossaryDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: undefined,
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
        };
    }

    patientsGroupsOpen = (routePath: string, same: boolean) => {
        const {signal} = this.abortController;
        const {client} = this.props;

        patientsGroupsOpen(
            client,
            signal,
            routePath,
            this,
            same,
            this.props.history,
            () => {
                this.forceUpdate();
            }
        );
    }

    isPatientsGroupsOpen = (routePath: string): boolean => {
        const activePatient: ActivePatient = getURLSearchParams(routePath);
        const {patientsGroups} = this.state;
        return patientsGroups[activePatient.patientType].open;
    }

    handleNosologiesClick = () => {
        const {nosologiesOpen} = this.state;

        this.setState({nosologiesOpen: !nosologiesOpen});
    }

    blockPrivatePatients = () => {
        const {intl} = this.props;
        const sTitleConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.title', defaultMessage: 'Title'});
        // const sTextConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.text', defaultMessage: 'Text'});
        const sTextConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.text'}, {telegram: `<a href="${config.options.telegram.ru}">Telegram</a>`, mailto: `<a href="mailto:partner@emedsup.org">partner@emedsup.org</a>`}, {defaultMessage: 'Text'});
        this.setState({
            openConfirmDialog: true,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: undefined,
            buttonNo: intl.formatMessage({id: 'label.close', defaultMessage: 'Сlose'}),
            onConfirmDialog: this.openConfirmDialog(false),
        });
    }

    openConfirmDialog = (open: boolean) => {
        this.setState({
            openConfirmDialog: open,
        });
    }

    openManualDialog = (open: boolean) => {
        const {setOpenManual, openManual} = this.context;
        // this.setState({
        //     openManualDialog: open,
        // });
        setOpenManual(!openManual);
    }

    openGlossaryDialog = (open: boolean) => {
        // const {setOpenGlossary, openGlossary} = this.context;
        this.setState({
            openGlossaryDialog: open,
        });
        // setOpenGlossary(!openGlossary);
    }

    render() {
        const {
            classes, className, disablePermanent,
            mobileOpen, onClose, onOpen, history, location, intl
        } = this.props;
        const {userProfile, setContextPatient, patientsGroups, setPatientsGroups} = this.context;
        const {locale, recursiveTreeViewSelected, isLoading} = this.context;
        const {
            // TODO: for ConfirmDialog
            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,
            openManualDialog,
            openGlossaryDialog,
        } = this.state;

        const drawer = <div className={classes.nav}>
            <div className={classes.toolbarIe11}>
                <div className={classes.toolbar}>
                    <Typography className={classes.linkHead} variant="h6" color="inherit" component={Link} to="/">
                        <FormattedMessage id="app.name" defaultMessage="eMedSupport"/>
                    </Typography>
                    <Hyphenated language={getHyphenedLang(locale)}>
                        <Typography className={classes.contentVerticalPadding} variant={"body2"} color={"primary"}>
                            {intl.formatMessage({id: 'message.terms_use', defaultMessage: 'Only for educational using'})}
                        </Typography>
                    </Hyphenated>
                </div>
            </div>
            <Divider/>
            {userProfile && userProfile.user &&
            <List>
                {pagePatients
                    .filter(item => intersect(item.authorities, userProfile.user.authorities).length > 0)
                    .map(item => (
                        <React.Fragment key={item.path}>
                            <ListItem
                                button
                                disabled={isLoading}
                                selected={patientsRouteCompare(item.path, location.pathname, location.search)}
                                key={item.path}
                                onClick={event => {
                                    clearContextPatient(setContextPatient, () => {
                                        const patientType = getPatientTypeFromPath(item.path);
                                        if (isShowGroups(item.path.split('?')[1])) {
                                            closePublicPatientsGroups(patientType, patientsGroups, setPatientsGroups, () => {
                                                this.patientsGroupsOpen(`${item.path}${recursiveTreeViewSelected[patientType] ? `&group=${recursiveTreeViewSelected[patientType]}` : ``}`, patientsRouteCompare(item.path, location.pathname, location.search));
                                            });
                                        } else {
                                            if (isPrivatePatientsEnable()) {
                                                closePublicPatientsGroups(patientType, patientsGroups, setPatientsGroups, () => {
                                                    history.replace(item.path);
                                                });
                                            } else {
                                                this.blockPrivatePatients();
                                            }
                                        }
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name}/>
                                {/*{isShowGroups(item.path.split('?')[1]) &&*/}
                                {/*    <ListItemSecondaryAction>*/}
                                {/*        <IconButton*/}
                                {/*            aria-label="expand group"*/}
                                {/*            size="small"*/}
                                {/*            color="default"*/}
                                {/*            onClick={() => this.patientsGroupsOpen(item.path.split('?')[1])}*/}
                                {/*        >*/}
                                {/*            {this.isPatientsGroupsOpen(item.path.split('?')[1]) ?*/}
                                {/*                <ExpandLess*/}
                                {/*                    edge="end"*/}
                                {/*                />*/}
                                {/*                :*/}
                                {/*                <ExpandMore*/}
                                {/*                    edge="end"*/}
                                {/*                />*/}
                                {/*            }*/}
                                {/*        </IconButton>*/}
                                {/*    </ListItemSecondaryAction>*/}
                                {/*}*/}
                            </ListItem>
                            {/*{isShowGroups(item.path.split('?')[1]) &&*/}
                            {/*    <Collapse in={this.isPatientsGroupsOpen(item.path.split('?')[1])} timeout="auto"*/}
                            {/*              unmountOnExit>*/}
                            {/*        /!*<List component="div" disablePadding>*!/*/}
                            {/*        /!*    {getViewPatientsGroups(patientsGroups, history, setContextPatient, this.props.location.search, item.path)}*!/*/}
                            {/*        /!*</List>*!/*/}
                            {/*        <RecursiveTreeView*/}
                            {/*            patientsGroups={patientsGroups}*/}
                            {/*            history={history}*/}
                            {/*            setContextPatient={setContextPatient}*/}
                            {/*            locationSearch={this.props.location.search}*/}
                            {/*            routePath={item.path}*/}
                            {/*            context={this.context}*/}
                            {/*        />*/}
                            {/*    </Collapse>*/}
                            {/*}*/}
                        </React.Fragment>
                    ))}

                {config.options['manual'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['manual'][locale]}
                        target="_blank"
                        krel="noopener noreferrer"
                        // onClick={event => {
                        //     this.openManualDialog(true);
                        //     // history.replace(item.path);
                        // }}
                    >
                        <ListItemIcon>
                            <HelpIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.manual', defaultMessage: 'Manual'})}
                        />
                    </ListItem>
                }
            </List>
            }
            <Divider/>
            {/* {userProfile && userProfile.user &&
            <List>
                <ListItem button onClick={this.handleNosologiesClick}>
                    <ListItemIcon>
                        <AppsIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: 'page.nosologies.calculator', defaultMessage: 'Calculator'})} />
                    {nosologiesOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={nosologiesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {pages
                            .filter(item => intersect(item.authorities, userProfile.user.authorities).length > 0)
                            .map(item => (
                                <ListItem
                                    button
                                    selected={item.isPathMatch(location.pathname)}
                                    key={item.path}
                                    onClick={event => {
                                        history.replace(item.path);
                                    }}
                                    className={classes.nested}
                                >
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                </ListItem>
                            ))}
                    </List>
                </Collapse>
            </List>
            }
            <Divider/> */}
            {userProfile && userProfile.user &&
            <List>
                {pages2
                    .filter(item => intersect(item.authorities, userProfile.user.authorities).length > 0)
                    .map(item => (
                        <ListItem
                            button
                            disabled={isLoading}
                            selected={item.isPathMatch(location.pathname)}
                            key={item.path}
                            onClick={(event) => {
                                if (item.onClick) {
                                    item.onClick(this.context);
                                } else {
                                    history.replace(item.path);
                                }
                            }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name}/>
                        </ListItem>
                    ))}
                <ListItem
                    button
                    disabled={isLoading}
                    key={'glossary'}
                    // onClick={(event) => {
                    //     this.openGlossaryDialog(true);
                    // }}
                    >
                    <ListItemIcon>
                        <LiveHelpIcon color="action" />
                    </ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="page.glossary" defaultMessage="Glossary" />}/>
                </ListItem>
            </List>
            }
            <Divider/>
            <List>
                {config.options['about'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['about'][locale]}
                        target="_blank"
                        krel="noopener noreferrer">
                        <ListItemIcon>
                            <InfoIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.project.about', defaultMessage: 'About project'})}
                        />
                    </ListItem>
                }
                {config.options['linkedin'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['linkedin'][locale]}
                        target="_blank"
                        krel="noopener noreferrer">
                        <ListItemIcon>
                            <LinkedinIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.linkedin', defaultMessage: 'Linkedin'})}
                        />
                    </ListItem>
                }
                {config.options['instagram'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['instagram'][locale]}
                        target="_blank"
                        krel="noopener noreferrer">
                        <ListItemIcon>
                            <InstagramIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.instagram', defaultMessage: 'Instagram'})}
                        />
                    </ListItem>
                }
                {config.options['facebook'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['facebook'][locale]}
                        target="_blank"
                        krel="noopener noreferrer">
                        <ListItemIcon>
                            <FacebookIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.facebook', defaultMessage: 'Facebook'})}
                        />
                    </ListItem>
                }
                {config.options['contact'][locale] &&
                    <ListItem
                        button
                        disabled={isLoading}
                        component="a"
                        href={config.options['contact'][locale]}
                        target="_blank"
                        krel="noopener noreferrer">
                        <ListItemIcon>
                            <LanguageIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={intl.formatMessage({id: 'label.contact', defaultMessage: 'Contact information'})}
                        />
                    </ListItem>
                }
            </List>
            {config.options.ads.enable && (
                <div>
                    <PanelDivider/>
                    <PanelDivider/>
                    <Ads/>
                </div>
            )}
        </div>;

        return (
            <nav className={className}>
                <Hidden lgUp={!disablePermanent} implementation="js">
                    <SwipeableDrawer
                        classes={{
                            paper: classNames(classes.paper, 'algolia-drawer'),
                        }}
                        disableBackdropTransition={!iOS}
                        variant="temporary"
                        open={mobileOpen}
                        onOpen={onOpen}
                        onClose={onClose}
                        onClick={onClose}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </SwipeableDrawer>
                </Hidden>
                {disablePermanent ? null : (
                    <Hidden mdDown implementation="js">
                        <Drawer
                            classes={{
                                paper: classes.paper,
                            }}
                            variant="permanent"
                            // variant="temporary"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                )}
                {openConfirmDialog &&
                <ConfirmDialog
                    title={titleConfirmDialog}
                    text={textConfirmDialog}
                    buttonYes={buttonYes}
                    buttonNo={buttonNo}
                    open={openConfirmDialog}
                    setOpen={this.openConfirmDialog}
                    onConfirm={onConfirmDialog}
                >
                </ConfirmDialog>
                }
                {openManualDialog &&
                <ManualDialog
                    open={openManualDialog}
                    setOpen={this.openManualDialog}
                    pdf={config.options['manual'][locale]}
                    contents={[
                        '/instructions/help_001.html',
                        '/instructions/help_001.html',
                        '/instructions/help_001.html',
                    ]}
                >
                </ManualDialog>
                }
                {openGlossaryDialog &&
                <GlossaryDialog
                    intl={intl}
                    open={openGlossaryDialog}
                    setOpen={this.openGlossaryDialog}
                >
                </GlossaryDialog>
                }
            </nav>
        );
    }
}

AppDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    disablePermanent: PropTypes.bool.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
};

AppDrawer.contextTypes = {
    userProfile: PropTypes.object,
    locale: PropTypes.string.isRequired,
    setContextContent: PropTypes.func,
    setContextPatient: PropTypes.func,
    setRecursiveTreeViewExpanded: PropTypes.func,
    recursiveTreeViewExpanded: PropTypes.object,
    setRecursiveTreeViewSelected: PropTypes.func,
    recursiveTreeViewSelected: PropTypes.object,
    patientsGroups: PropTypes.object,
    setPatientsGroups: PropTypes.func,
    setOpenManual: PropTypes.func,
    isLoading: PropTypes.bool,
    openManual: PropTypes.bool,
};

export default compose(
    withRouter,
    withStyles(styles),
    withApollo,
    injectIntl,
)(AppDrawer);

import React from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import AppFrame from './AppFrame';
import AppContent from './AppContent';
import {pages, pages2} from './tileData';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Home from './Home';
import AppTableOfContents from './AppTableOfContents';
import Profile from './Profile';
import compose from 'recompose/compose';
import withRoot from '../withRoot';
import Baseline from './Baseline';
import Hyper from './Hyper';
import {Hidden} from "@material-ui/core";
import HeartFailure from "./HeartFailure";
import Charts from "./Charts";
import BreastCancer from "./BreastCancer";
import Box from "@material-ui/core/Box";
import ManualView from "../comp/common/ManualView";
import PanelDivider from '../comp/PanelDivider';
import mainStyles from '../styles/mainStyles';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {DyslipidemiaIcon} from "../icons";
import Dyslipidemia from "./Dyslipidemia";
import Ibs from "./Ibs";

const styles = theme => ({
    ...mainStyles(theme),
});

class Index extends React.Component {

    PrivateRoute = ({component: Component, ...rest}) => (
        <Route
            {...rest}
            render={props =>
                this.context.isAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );

    componentDidMount() {
        // document.addEventListener('click', (event) => {console.error('+++ event:', event)}, {
        //     // passive: true
        // });
    }

    onRouteChange = (event) => {
    }

    render() {
        const {classes} = this.props;
        const {contextContent, openManual, showLoading, hideLoading} = this.context;

        return (
            <Router onChange={this.onRouteChange}>
                <AppFrame>
                    <AppContent className={classes.root}>
                        {openManual &&
                        <Box>
                            <ManualView
                                setOpen={this.context.setOpenManual}
                                showLoading={showLoading}
                                hideLoading={hideLoading}
                            />
                        </Box>
                        }
                        <Switch>
                            <Route path="/signin" component={SignIn}/>
                            <Route path="/signup" component={SignUp}/>
                            <Route path="/password-reset" component={SignUp}/>
                            <Route path="/reset" component={SignIn}/>
                            <Route path="/profile" component={Profile}/>
                            <Route path="/patients" component={Profile}/>
                            <Route path="/patients?patientType=PRIVATE" component={Profile}/>
                            <Route path="/patients?patientType=PUBLIC" component={Profile}/>
                            <Route path="/baseline" component={Baseline}/>
                            <Route path="/hyper" component={Hyper}/>
                            <Route path="/heartfailure" component={HeartFailure}/>
                            <Route path="/breastcancer" component={BreastCancer}/>
                            <Route path="/dyslipidemia" component={Dyslipidemia}/>
                            <Route path="/ibs" component={Ibs}/>
                            <Route path="/charts" component={Charts}/>
                            {pages.map(item => (
                                <this.PrivateRoute
                                    exact={item.isExact()}
                                    key={item.path}
                                    path={item.path}
                                    component={item.component}
                                />
                            ))}
                            {pages2.map(item => (
                                <this.PrivateRoute
                                    exact={item.isExact()}
                                    key={item.path}
                                    path={item.path}
                                    component={item.component}
                                />
                            ))}
                            <Route component={Home}/>
                        </Switch>
                        <Box>
                            <PanelDivider />
                            <Box className={classes.contentBoxCenter}>
                                <Typography color="primary">
                                    <FormattedMessage id='label.company.rights' defaultMessage='© Emed Support Systems LTD All rights reserved' />
                                </Typography>
                                <Typography color="primary">
                                    <FormattedMessage id='label.version' defaultMessage='Version 1.0.0' />
                                </Typography>
                            </Box>
                        </Box>
                    </AppContent>
                    <Hidden smDown implementation="js">
                        {contextContent &&
                        <AppTableOfContents/>
                        }
                    </Hidden>
                </AppFrame>
            </Router>
        );
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

Index.contextTypes = {
    isAuth: PropTypes.func,
    contextContent: PropTypes.object,
    setOpenManual: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    openManual: PropTypes.bool,
};

export default compose(
    withRoot,
    withStyles(styles),
)(Index);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import RootContainer from '../comp/RootContainer';
import ContentPanel from '../comp/ContentPanel';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import PanelDivider from "../comp/PanelDivider";
import ContainerCenter from "../comp/ContainerCenter";
import remarkGfm from "remark-gfm";
import {Button, Grid} from "@material-ui/core";
import Tariffs from "../comp/Tariffs";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import { Role } from '../const';
import config from '../config';
import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';
import PlayerView from "../comp/common/PlayerView";

const styles = theme => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    ...mainStyles(theme),
    contentBox: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    tariffsHead: {
        fontWeight: '600',
        marginBottom: '30px'
    },
    VREPlayerWrapper: {
        width: '560px',
        height: '315px',
    }
});

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.error('+++++++++ config:', config);
        // const $this = this;
        const {locale} = this.context;

        fetch(`/md/registration_${locale}.md`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                this.setState({
                    content_registration: content,
                });
            })
            .catch((err) => {
                console.error(err);
            });

        fetch(`/md/disclaimer_${locale}.md`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                this.setState({
                    content_disclaimer: content,
                });
            })
            .catch((err) => {
                console.error(err);
            });

        fetch(`/md/rko.md`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                this.setState({
                    content_rko: content,
                });
            })
            .catch((err) => {
                console.error(err);
            });


    }

    render() {
        const {classes} = this.props;
        const {content_registration, content_disclaimer, content_rko, videoParams} = this.state;
        const {locale, showNotify, patientsGroups, userProfile} = this.context;

        return (
            <RootContainer>
                <ContentPanel>
                    <PanelDivider/>
                    {config.options['video'][locale] &&
                        <ContainerCenter>
                            {/*<iframe*/}
                            {/*    title="MedicBK video"*/}
                            {/*    width="560"*/}
                            {/*    height="315"*/}
                            {/*    src={config.options['video'][locale]}*/}
                            {/*    frameBorder="0"*/}
                            {/*    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                            {/*    allowFullScreen/>*/}
                            {/*<ReactPlayer*/}
                            {/*    url={config.options['video'][locale]}*/}
                            {/*    controls={true}*/}
                            {/*/>*/}
                            {/*<div className={classes.VREPlayerWrapper}>*/}
                            {/*    <VREPlayer*/}
                            {/*        // hideList={null}*/}
                            {/*        playerOptions={{*/}
                            {/*            // src: config.options['video'][locale],*/}
                            {/*            controls: true,*/}
                            {/*            autoplay: false,*/}
                            {/*            preload: 'metadata',*/}
                            {/*            width: 640,*/}
                            {/*            height: 360,*/}
                            {/*        }}*/}
                            {/*        videojsOptions={*/}
                            {/*            {*/}
                            {/*                fluid: true,*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*        resources={{*/}
                            {/*            sources: [*/}
                            {/*                {*/}
                            {/*                    src: config.options['video'][locale],*/}
                            {/*                    type: 'video/type'*/}
                            {/*                }*/}
                            {/*            ],*/}
                            {/*        }}*/}
                            {/*        // onReady={(player) => console.log(player)}*/}
                            {/*        // onPlay={(e, _, second) => console.log('Play!')}*/}
                            {/*        // onPause={(e, _, second) => console.log('Pause!')}*/}
                            {/*        // onEnded={(e, _) => console.log('Ended!')}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {(!!config.options['video'][locale] && !!config.options['video'][locale].length) &&
                                <div className={classes.VREPlayerWrapper}>
                                    <PlayerView videos={config.options['video'][locale]}/>
                                </div>
                            }

                        </ContainerCenter>
                    }
                    <div className={classes.contentBox}>
                        {(!this.context.isAuth()) &&
                        <React.Fragment>
                            <Typography variant="body1" component="span">
                                {content_registration &&
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                               children={content_registration}/>}
                            </Typography>
                            <Grid container direction={'column'} spacing={2} style={{marginTop: '18px'}}
                                  alignItems={"center"}
                                  justifyContent={"center"}>
                                <Grid item alignItems={"center"} justifyContent={"center"}>
                                    <Button color="primary" variant="contained" component={Link} to="/signup?type=10">
                                        <FormattedMessage id="label.registration" defaultMessage="Registration"/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        }
                        {content_rko && userProfile && userProfile.user && (userProfile.user.authorities || []).indexOf(Role.RKO) !== -1 &&
                            <Typography variant="body1" component="span">
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                    children={content_rko} />
                            </Typography>
                        }
                        {/* <Typography variant="body1" component="span">
                            {content_disclaimer &&
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                           children={content_disclaimer}/>}
                        </Typography> */}
                    </div>

                    {/* <div className={classes.contentBox}>
                        <Typography variant={"h5"} className={classes.tariffsHead}>
                            Тарифы
                        </Typography>
                        <Tariffs showNotify={showNotify} patientsGroups={patientsGroups}/>
                    </div> */}
                </ContentPanel>
            </RootContainer>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

Home.contextTypes = {
    isAuth: PropTypes.func,
    isAnonymous: PropTypes.func,
    showNotify: PropTypes.func,
    patientsGroups: PropTypes.object,
    locale: PropTypes.string.isRequired,
    userProfile: PropTypes.object,
};

export default compose(
    withStyles(styles),
)(Home);

import React from 'react';
import ReactDOM from 'react-dom';
import config from './config';
import Index from './pages/index';
import registerServiceWorker from './registerServiceWorker';
import {Cookies, CookiesProvider} from 'react-cookie';
import {ApolloProvider, ApolloClient, HttpLink, ApolloLink, Observable, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import PropTypes from 'prop-types';
import {pages} from './pages/tileData';
import LocaleProvider from './i18n/LocaleProvider';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import {getUser, getUserPermissions} from "./query/user";
import {
    isSameRemoteServer,
    isSameAuthServer,
    getQueryResult,
    setCookies,
    removeCookies,
    getContentsManual,
} from './utils';
import moment from 'moment-timezone';
import {loadGetStaff} from "./query/patient";
import {createNetworkStatusNotifier} from 'react-apollo-network-status';
import {
    NosologyAPIUrl,
    NosologyType,
    PatientType,
    ProfileModeEnum,
    ProfileProfileModeEnum,
} from "./const";
import ErrorBoundary from "./comp/ErrorBoundary";
// import GitInfo from 'react-git-info/macro';
import userPermissions from "./service/user-permissons";
import userPermissionsStore from "./service/user-permissons";
import {Box, Grid, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FormattedMessage} from "react-intl";
// import packageJson from '../package.json';

const {link, useApolloNetworkStatus} = createNetworkStatusNotifier();

class AppConfig extends React.Component {

    static childContextTypes = {
        cookies: PropTypes.object,
        userProfile: PropTypes.object,
        isAuth: PropTypes.func,
        isAnonymous: PropTypes.func,
        updateUser: PropTypes.func,

        getActivePage: PropTypes.func,

        setOpenManual: PropTypes.func,
        openManual: PropTypes.bool,

        setContextContent: PropTypes.func,
        contextContent: PropTypes.object,

        setContextContentShow: PropTypes.func,
        contextContentShow: PropTypes.bool,

        setContextPatient: PropTypes.func,
        contextPatient: PropTypes.object,

        setRecursiveTreeViewExpanded: PropTypes.func,
        recursiveTreeViewExpanded: PropTypes.object,

        resetRecursiveTreeViewSelected: PropTypes.func,
        setRecursiveTreeViewSelected: PropTypes.func,
        recursiveTreeViewSelected: PropTypes.object,

        setPatientsGroups: PropTypes.func,
        patientsGroups: PropTypes.object,

        patientGroupSelected: PropTypes.object,

        setContextStaff: PropTypes.func,
        contextStaff: PropTypes.object,

        setUser: PropTypes.func,

        setNosologyDataContext: PropTypes.func,
        nosologyDataContext: PropTypes.object,

        setTableStateContext: PropTypes.func,
        tableStateContext: PropTypes.object,

        setPatientsStateContext: PropTypes.func,
        patientsStateContext: PropTypes.object,

        setPatientNosologyStateContext: PropTypes.func,
        patientNosologyStateContext: PropTypes.object,

        setPatientDataContext: PropTypes.func,
        patientDataContext: PropTypes.object,

        selectQuestion: PropTypes.number,
        setSelectQuestion: PropTypes.func,

        questionArticles: PropTypes.array,
        setQuestionArticles: PropTypes.func,

        notifyMessage: PropTypes.string,
        notifyOpen: PropTypes.bool,
        notifyType: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
        showNotify: PropTypes.func,
        hideNotify: PropTypes.func,

        // loading
        isLoading: PropTypes.bool,
        useApolloNetworkStatus: PropTypes.func,
        showLoading: PropTypes.func,
        hideLoading: PropTypes.func,
        resetLoading: PropTypes.func,

        locale: PropTypes.string,
        setLocale: PropTypes.func,
        localesAvailable: PropTypes.array,
    };

    cookies = new Cookies();

    state = {
        configOptionLoaded: false,
        configOptionLoadedError: false,
    };

    constructor(props) {
        // console.log('MedicBK version:', packageJson.version);
        console.log(`MedicBK version: ${process.env.REACT_APP_VERSION}`)

        super(props);

        moment.tz.setDefault(moment.tz.guess());

        fetch('/config/config.json', {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                // const gitInfo = GitInfo();
                // console.log('git branch:', gitInfo.branch);
                // console.log('git commit.date:', gitInfo.commit.date);
                // console.log('git commit.hash:', gitInfo.commit.hash);
                // console.log('git commit.message:', gitInfo.commit.message);
                // console.error('content:', content);

                let loadedConfig;
                try {
                    if (!!content) {
                        loadedConfig = JSON.parse(content);
                    }
                    // console.error('loadedConfig:', loadedConfig);

                    if (!!loadedConfig) {
                        config.options = (loadedConfig || {}).options;
                        config.params = (loadedConfig || {}).params;
                        // console.error('config.options:', config.options);
                        // console.error('config.params:', config.params);
                        NosologyAPIUrl[NosologyType.HYPERTENSION] = config.options.server.hp_url;
                        NosologyAPIUrl[NosologyType.ATRIAL_FIBRILLATION] = config.options.server.fl_url;
                        NosologyAPIUrl[NosologyType.HEART_FAILURE] = config.options.server.heart_failure_url;

                        this.httpLink = new HttpLink({
                            uri: config.options.server.api_url + config.options.server.api_uri,
                            credentials: 'include'
                        });
                        // this.setState({
                        //     configOptionLoaded: true,
                        // }, () => {
                        this.loadUserInfo();
                        // });
                    } else {
                        this.setState({
                            configOptionLoadedError: true,
                        });
                    }

                    if (!!loadedConfig) {
                        this.setState({
                            configOptionLoadedError: false,
                        });
                    }
                } catch (error) {
                    console.error('Не удалось загрузить файл конфигурации');
                    console.error('+++ error:', error);
                    this.setState({
                        configOptionLoadedError: true,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });

        fetch('/config/gitLogInfo.json', {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                // const gitInfo = GitInfo();
                // console.error('content:', content);

                let loadedJson;
                try {
                    if (!!content) {
                        loadedJson = JSON.parse(content);
                    }
                    loadedJson = loadedJson || {};
                    // console.error('+++ loadedJson:', loadedJson);
                    if (!!loadedJson) {
                        console.log('git commit.hash:', loadedJson.commit);
                        console.log('git commit.date:', loadedJson.date);
                    }

                } catch (error) {
                    console.error('Не удалось загрузить файл gitLogInfo.json');
                    console.error('+++ error:', error);
                }
            })
            .catch((err) => {
                console.error(err);
            });

        this.state = {
            tableStateContext: {},
            contextContent: null,
            recursiveTreeViewExpanded: {
                [PatientType.PUBLIC]: [],
                [PatientType.EDUCATION]: [],
                [ProfileModeEnum.PROFILE]: [],
            },
            recursiveTreeViewSelected: {
                [PatientType.PUBLIC]: '',
                [PatientType.EDUCATION]: '',
                [ProfileModeEnum.PROFILE]: '',
            },
            selectQuestion: -1,
            notifyOpen: false,
            notifyMessage: '',
            reqCount: 0,
            locale: this.getLocale(),
            patientsGroups: {
                [PatientType.PRIVATE]: {
                    open: false,
                    data: [],
                },
                [PatientType.PUBLIC]: {
                    open: false,
                    data: [],
                },
                [PatientType.EDUCATION]: {
                    open: false,
                    data: [],
                },
                [ProfileModeEnum.PROFILE]: {
                    open: false,
                    data: [
                        {
                            active: true,
                            id: "1",
                            name: "Profile",
                            ord: 0,
                            type: "PROFILE",
                            mode: ProfileProfileModeEnum.PROFILE_INFO,
                        },
                        {
                            active: true,
                            id: "2",
                            name: "Tariffs",
                            ord: 1,
                            type: "PROFILE",
                            mode: ProfileProfileModeEnum.PROFILE_TARIFFS,
                        },
                    ],
                },
            },
        };
    }

    componentDidMount() {
        // this.loadUserInfo();
    }

    isAuth = () => {
        return Boolean(this.getAuthToken());
    };

    isAnonymous = () => {
        return true;
    };

    getAuthToken = () => {
        let authToken = this.getCookies('access-token');

        if (!Boolean(authToken) && localStorage) {
            authToken = localStorage.getItem('x_auth_token');
        }

        return authToken;
    };

    getXSRFToken = () => {
        if (isSameAuthServer()) {
            return this.getCookies('x-xsrf-token');
        } else {
            return undefined;
        }
    };

    getLocale = () => {
        let locale = this.getCookies('locale');
        if (!Boolean(locale)) {
            locale = 'en';
            setCookies(this.cookies, 'locale', locale);
        }
        return locale;
    };

    setLocale = (locale) => {
        setCookies(this.cookies, 'locale', locale);
        this.setState({
            locale: locale,
        });
    };

    getCookies = (name: string) => {
        return this.cookies.get(name);
    };

    setCookies = (name: string, value) => {
        this.cookies.set(
            name,
            value,
            {path: '/'}
        );
    };

    loadUserInfo = (callback) => {
        if (
            this.isAnonymous() ||
            (this.isAuth() && !this.state.user)
        ) {
            this.client.query({
                query: getUser,
                context: {
                    uri: config.options.server.api_url + config.options.server.api_uri,
                },
            })
                .then((result) => getQueryResult(result?.data, 'getUserProfile'))
                .then((profile) => {
                    this.client.query({
                        query: getUserPermissions,
                        context: {
                            uri: config.options.server.api_url + config.options.server.api_uri,
                        },
                    })
                        .then((result) => getQueryResult(result?.data, 'getUserPermissions'))
                        .then((userPermissions) => {
                            userPermissionsStore.userPermissions = userPermissions;
                            this.getStaff(() => {
                                this.setState({
                                    userProfile: profile,
                                    configOptionLoaded: true,
                                }, () => {
                                    if (!!callback) {
                                        callback();
                                    }
                                });
                            });
                        });
                });
        } else {
            this.setState({
                configOptionLoaded: true,
            }, () => {
                if (!!callback) {
                    callback();
                }
            });
        }
    };

    getStaff = (callback) => {
        const {contextStaff} = this.state;
        loadGetStaff(this.client, undefined, contextStaff, undefined)
            .then((staff) => {
                this.setState({
                    contextStaff: (staff || {}),
                }, () => {
                    this.forceUpdate();

                    if (!!callback) {
                        callback();
                    }
                });
            });
    };

    request = async (operation) => {
        const context = operation.getContext();

        if (isSameAuthServer() && isSameRemoteServer((context || {}).uri)) {
            let xsrfToken = this.getXSRFToken();
            if (Boolean(xsrfToken)) {
                operation.setContext({
                    headers: {
                        'X-Xsrf-Token': this.getXSRFToken()
                    }
                });
            }
        } else {
            let headers = {
                'locale': this.getLocale()
            };

            let authToken = this.getAuthToken();
            if (Boolean(authToken)) {
                headers['X-Auth-Token'] = `Bearer ${authToken}`;
            }

            operation.setContext({
                headers: headers
            });
        }
    };

    showNotify = (message, type) => {
        this.setState({
            notifyMessage: message,
            notifyOpen: true,
            notifyType: type,
        });
    };

    showLoading = () => {
        const {reqCount} = this.state;
        this.setState({
            reqCount: reqCount + 1,
        });
    };

    hideLoading = () => {
        const {reqCount} = this.state;

        if (reqCount > 0) {
            this.setState({
                reqCount: reqCount - 1,
            });
        }
    };

    resetLoading = () => {
        this.setState({
            reqCount: 0,
        });
    };

    callHideLoading = () => {
        setTimeout(() => {
            this.hideLoading();
        }, 500);
    }

    isLoading = () => {
        return this.state.reqCount > 0;
    };

    requestLink = new ApolloLink((operation, forward) =>
        new Observable(observer => {
            let handle;
            Promise.resolve(operation)
                .then(oper => this.request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(reason => {
                    console.error('+++ CATCH +++ reason:', reason);
                    this.callHideLoading();
                    return observer.error.bind(observer)
                });

            const cleanUp = () => {
                this.resetLoading();
                handle?.unsubscribe();
            }
            const signal = operation?.getContext()?.fetchOptions?.signal;
            if (signal) {
                signal.onabort = cleanUp;
            }

            return () => {
                if (handle) {
                    if (!handle.closed) {
                        this.hideLoading();
                    }
                    handle.unsubscribe();
                }
            };
        })
    );

    logoutLink = onError(({networkError, graphQLErrors, response, operation, forward}) => {
        if (networkError && networkError.statusCode === 401) {
            console.log('+++ 401 +++ networkError:', networkError);

            if (localStorage) {
                localStorage.removeItem('x_auth_token');
            }

            removeCookies(this.cookies, 'access-token');
            removeCookies(this.cookies, 'x_auth_token');
            removeCookies(this.cookies, 'x-xsrf-token');

            const locale = this.getLocale();
            this.showNotify(locale === 'ru' ? 'Ошибка авторизации' : 'Authentication error', 'error');
            if (!!window.location.search) {
                window.location.replace('/');
            }
        }
    });

    httpLink = new HttpLink({
        uri: undefined,
        credentials: 'include'
    });

    reqStatusLink = new ApolloLink((operation, forward) => {
        this.showLoading();
        return forward(operation).map((data) => {
            this.callHideLoading();
            return data;
        })
    });

    connections: { [key: string]: any } = {};

    cancelRequestLink = new ApolloLink(
        (operation, forward) =>
            new Observable(observer => {
                // Set x-CSRF token (not related to abort use case)
                const context = operation.getContext();
                /** Final touch to cleanup */

                const connectionHandle = forward(operation).subscribe({
                    next: (...arg) => observer.next(...arg),
                    error: (...arg) => {
                        cleanUp();
                        observer.error(...arg);
                    },
                    complete: (...arg) => {
                        cleanUp();
                        observer.complete(...arg)
                    }
                });

                const cleanUp = () => {
                    connectionHandle?.unsubscribe();
                    delete this.connections[context.requestTrackerId];
                }

                if (context.requestTrackerId) {
                    const controller = new AbortController();
                    controller.signal.onabort = cleanUp;
                    operation.setContext({
                        ...context,
                        fetchOptions: {
                            signal: controller.signal,
                            ...context?.fetchOptions
                        },
                    });

                    if (this.connections[context.requestTrackerId]) {
                        // If a controller exists, that means this operation should be aborted.
                        this.connections[context.requestTrackerId]?.abort();
                    }

                    this.connections[context.requestTrackerId] = controller;
                }

                return connectionHandle;
            })
    );

    client = new ApolloClient({
        link: link.concat(ApolloLink.from([
            onError(({graphQLErrors, networkError, response, operation, forward}) => {
                this.callHideLoading();
                if (graphQLErrors) {
                    let messages = (graphQLErrors.map(error => error.message) || []).reduce((pre, cur) => pre + ' ' + cur);
                    this.showNotify(messages, 'error');
                    console.log('graphQLErrors:', graphQLErrors);
                    console.error('+++ graphQLErrors +++ response:', response);
                }
                if (networkError) {
                    console.log(networkError);
                    console.error('+++ networkError +++ response:', response);
                    if (!!networkError['error'] && !!networkError['error']['errors'] && networkError['error']['errors'][0]) {
                        console.error('unwrapping apollo network errors');
                        networkError.message = networkError['error']['errors'][0].message;
                        // you may also be able to set networkError.message to null based on criteria to remove the error, even if you can't prevent an error from being triggered altogether
                    }
                    // forward(operation);
                    console.error('+++ networkError +++ networkError:', networkError);
                    console.error('+++ networkError +++ networkError.statusCode:', networkError.statusCode);
                    if (networkError && networkError.statusCode === 401) {
                        const locale = this.getLocale();
                        this.showNotify(locale === 'ru' ? 'Ошибка авторизации' : 'Authentication error', 'error');
                        console.error('+++ 401 --- networkError +++ networkError:', networkError);
                        if (!!window.location.search) {
                            window.location.replace('/');
                        }
                        return;
                    }
                    this.showNotify('Ошибка подключения', 'error');

                    return Observable.of(
                        response = {
                            data: {},
                            errors: networkError.message,
                        }
                    );
                }
            }),
            this.requestLink,
            this.reqStatusLink,
            this.logoutLink.concat(this.httpLink),
        ])),
        cache: new InMemoryCache({
            addTypename: false,
        }),
        // TODO: add resolvers for state management
        // resolvers: StateResolvers(getState, writeState),
        // TODO: This is what enables cancellation
        queryDeduplication: false,
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        }
    });

    getChildContext() {
        return {

            isAuth: () => {
                return this.isAuth();
            },

            isAnonymous: () => {
                return this.isAnonymous();
            },

            updateUser: (callback) => {
                this.loadUserInfo(callback);
            },

            userProfile: this.state.userProfile,

            getActivePage: () => {
                return pages.find(p => p.isPathMatch(window.location.pathname));
            },

            setContextContent: (newContent, callback) => {
                this.setState({
                    contextContent: newContent,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            contextContent: this.state.contextContent,

            setContextContentShow: (show, callback) => {
                this.setState({
                    contextContentShow: show,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            contextContentShow: this.state.contextContentShow,

            setOpenManual: (openManual, callback) => {
                const contents = getContentsManual();
                this.setState({
                    openManual: contents && contents.length > 0 && openManual,
                }, () => {
                    this.forceUpdate(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },
            openManual: this.state.openManual,

            setContextPatient: (newPatient, callback) => {
                this.setState({
                    contextPatient: newPatient,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            contextPatient: this.state.contextPatient,

            setRecursiveTreeViewExpanded: (patientType, nodeIds, callback) => {
                this.setState(prevState => ({
                    recursiveTreeViewExpanded: {
                        ...prevState.recursiveTreeViewExpanded,
                        [patientType]: nodeIds,
                    },
                }), () => {
                    this.forceUpdate(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },
            recursiveTreeViewExpanded: this.state.recursiveTreeViewExpanded,

            setRecursiveTreeViewSelected: (patientType, nodeIds, selectedNode, callback) => {
                this.setState(prevState => ({
                    recursiveTreeViewSelected: {
                        ...prevState.recursiveTreeViewSelected,
                        [patientType]: nodeIds,
                    },
                    patientGroupSelected: {
                        ...prevState.patientGroupSelected,
                        [patientType]: selectedNode,
                    },
                }), () => {
                    this.forceUpdate(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },
            recursiveTreeViewSelected: this.state.recursiveTreeViewSelected,

            resetRecursiveTreeViewSelected: (callback) => {
                this.setState(prevState => ({
                    recursiveTreeViewSelected: {
                        [PatientType.PUBLIC]: '',
                        [PatientType.EDUCATION]: '',
                        [ProfileModeEnum.PROFILE]: '1',
                    },
                }), () => {
                    this.forceUpdate(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },

            setPatientsGroups: (patientsGroups, callback) => {
                this.setState({patientsGroups: patientsGroups}, () => {
                    this.forceUpdate(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },
            patientsGroups: this.state.patientsGroups,

            patientGroupSelected: this.state.patientGroupSelected,

            setContextStaff: (staff, callback) => {
                this.setState({
                    contextStaff: staff,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            contextStaff: this.state.contextStaff,

            setTableStateContext: (tableStateContext, callback) => {
                this.setState({
                    tableStateContext: tableStateContext
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            tableStateContext: this.state.tableStateContext,

            setPatientsStateContext: (patientsStateContext) => {
                this.setState({
                    patientsStateContext: patientsStateContext,
                });
            },
            patientsStateContext: this.state.patientsStateContext,

            setPatientNosologyStateContext: (patientNosologyStateContext) => {
                this.setState({
                    patientNosologyStateContext: patientNosologyStateContext,
                });
            },
            patientNosologyStateContext: this.state.patientNosologyStateContext,

            setPatientDataContext: (patientDataContext, callback) => {
                this.setState({
                    patientDataContext: patientDataContext,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            patientDataContext: this.state.patientDataContext,

            setNosologyDataContext: (nosologyDataContext, callback) => {
                this.setState({
                    nosologyDataContext: nosologyDataContext,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            nosologyDataContext: this.state.nosologyDataContext,

            setSelectQuestion: (newSelectQuestion) => {
                this.setState({
                    selectQuestion: newSelectQuestion,
                });
            },
            selectQuestion: this.state.selectQuestion,

            questionArticles: this.state.questionArticles,
            setQuestionArticles: (articles) => {
                this.setState({
                    questionArticles: articles,
                });
            },

            notifyMessage: this.state.notifyMessage,
            notifyOpen: this.state.notifyOpen,
            notifyType: this.state.notifyType ? this.state.notifyType : 'info',
            showNotify: (message, type) => {
                this.showNotify(message, type);
            },
            hideNotify: () => {
                this.setState({
                    notifyOpen: false,
                });
            },

            // loading
            isLoading: this.isLoading(),
            showLoading: () => {
                this.showLoading();
            },
            hideLoading: () => {
                this.hideLoading();
            },
            resetLoading: () => {
                this.resetLoading();
            },

            locale: this.state.locale,
            setLocale: (locale) => {
                this.setLocale(locale);
            },
            localesAvailable: [
                // {
                //     locale: 'ru',
                //     name: 'Русский',
                // },
                {
                    locale: 'en',
                    name: 'English',
                },
            ],

            useApolloNetworkStatus: useApolloNetworkStatus,

        }
    }

    render() {
        const {
            configOptionLoaded,
            configOptionLoadedError,
        } = this.state;
        return (
            <CookiesProvider cookies={this.cookies}>
                <LocaleProvider locale='en'>
                    <ApolloProvider client={this.client}>
                        <ErrorBoundary>
                            {!configOptionLoadedError ? (
                                !!configOptionLoaded ?
                                    <Index/>
                                    :
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <Box style={{textAlign: 'center', padding: '16px'}}>
                                                <CircularProgress/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                            )
                            :
                            <Grid container justifyContent='center'>
                                <Grid item>
                                    <Typography variant={"body1"} align={"center"}>
                                        <FormattedMessage id={'label.config.error'} defaultMessage={'Config error'}/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                        </ErrorBoundary>
                    </ApolloProvider>
                </LocaleProvider>
            </CookiesProvider>
        );
    }
}

ReactDOM.render(<AppConfig/>, document.querySelector('#root'));
registerServiceWorker();

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom'
import {TextField} from '@material-ui/core';
import validator from 'validator';
import compose from 'recompose/compose';
import {withApollo} from '@apollo/client/react/hoc';
import {withRouter} from 'react-router'
import config from '../config';
import {FormattedMessage, injectIntl} from 'react-intl';
import mainStyles from "../styles/mainStyles";
import {isSameAuthServer} from '../utils';

const styles = theme => ({
    ...mainStyles(theme),
    root: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3),
    },
    avatar: {
        margin: theme.spacing(1),
        padding: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    info_head: {
        marginTop: theme.spacing(4),
    },
    info_head_reset: {
        marginTop: theme.spacing(1),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    asDiv: {
        display: 'block',
    },
});

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        const {intl} = props;

        this.state = {
            fields: new Map([
                ['username', ''],
                ['password', ''],
            ]),
            errors: new Map(),

            showReset: false,
        };

        // let $this = this;
        this.validate = {
            'username': (value) => {
                let errors = this.state.errors;

                if (value.length < 5 || !validator.isEmail(value)) {
                    errors.set('username', intl.formatMessage({
                        id: 'err.login.invalid',
                        defaultMessage: 'Login invalid'
                    }));
                } else {
                    errors.delete('username');
                }

                return errors;
            },
            'password': (value) => {
                let errors = this.state.errors;

                if (value.length === 0) {
                    errors.set('password', intl.formatMessage({
                        id: 'err.password.empty',
                        defaultMessage: 'Password not empty'
                    }));
                } else {
                    errors.delete('password');
                }

                return errors;
            },
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {showReset} = this.state;
        const {location} = this.props;
        if (!showReset && location.pathname === '/reset') {
            this.setState({
                showReset: true,
            });
        }
        if (showReset && location.pathname === '/signin') {
            this.setState({
                showReset: false,
            });
        }
    }

    onValueChange = (event) => {
        let target = event.target;

        this.setState({
            fields: this.state.fields.set(target.name, target.value),
            errors: this.validate[target.name](target.value),
        });
    };

    setLoading = (loading) => {
        const {showLoading, hideLoading} = this.context;
        if (loading) {
            showLoading();
        } else {
            hideLoading();
        }
        this.setState({
            loading: loading,
        });
    }

    onSubmit = (event) => {
        event.preventDefault();

        const {history} = this.props;
        const {showReset, fields} = this.state;
        // const fields = this.state.fields;
        // const $this = this;

        const uri = config.options.server.auth_url + (!showReset ? config.options.server.signin_uri : config.options.server.request_password_reset_uri);
        const body = !showReset ?
            JSON.stringify({
                'username': fields.get('username'),
                'password': fields.get('password'),
                'client': isSameAuthServer() ? 'web' : 'app',
            })
            :
            fields.get('username');

        this.setLoading(true);

        fetch(uri, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 'ok') {
                    if (showReset) {
                        this.context.showNotify(response.data, 'success');
                    } else {

                        if (!isSameAuthServer() && localStorage && response.data && response.data.token) {
                            localStorage.setItem('x_auth_token', response.data.token);
                        }
                        this.context.updateUser(() => {
                            history.replace('/');
                        });
                    }
                } else {
                    this.context.showNotify(response.data, 'error');
                }
                this.setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                this.context.showNotify(err, 'error');
                this.setLoading(false);
            });
    };

    getDisable = (): boolean => {
        const {showReset, errors, fields, loading} = this.state;
        // return errors.size > 0
        if (showReset) {
            return loading || !fields.get('username') || errors.get('username');
        } else {
            return loading || !fields.get('username') || !fields.get('password') || errors.size > 0;
        }
    }

    render() {
        const {classes, intl} = this.props;
        const {showReset, errors, fields} = this.state;

        return (
            <React.Fragment>
                <CssBaseline/>
                <main className={classes.root}>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockIcon/>
                        </Avatar>
                        <Typography variant="h5">
                            {showReset ?
                                <FormattedMessage id='message.account.reset_password' defaultMessage='Reset password'/>
                                :
                                <FormattedMessage id='label.enter' defaultMessage='Enter'/>
                            }
                        </Typography>
                        <form className={classes.form} onSubmit={this.onSubmit}>
                            <TextField
                                id="username"
                                name="username"
                                autoComplete="username"
                                value={fields['username']}
                                onChange={this.onValueChange}
                                autoFocus
                                margin="normal"
                                required
                                fullWidth
                                label={intl.formatMessage({id: 'label.email', defaultMessage: 'Email'})}
                                error={errors.get('username') != null}
                                helperText={errors.get('username')}
                            />
                            {!showReset &&
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                value={fields['password']}
                                onChange={this.onValueChange}
                                margin="normal"
                                required
                                fullWidth
                                label={intl.formatMessage({id: 'label.password', defaultMessage: 'Password'})}
                                error={errors.get('password') != null}
                                helperText={errors.get('password')}
                            />
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={this.getDisable()}
                                className={classes.submit}>
                                {showReset ?
                                    <FormattedMessage id='label.send' defaultMessage='Send'/>
                                    :
                                    <FormattedMessage id='label.enter' defaultMessage='Enter'/>
                                }
                            </Button>
                            <Typography className={classes.info_head} variant="body2">
                                <FormattedMessage id='message.account.none' defaultMessage='Not account?'/>
                            </Typography>
                            <Link className={classes.link} to="/signup?type=10">
                                <FormattedMessage id='message.account.create' defaultMessage='Create new account'/>
                            </Link>
                            {/*<Typography className={classes.info_head_reset} variant="body2">*/}
                            {/*  <FormattedMessage id='message.account.forgot_your_password' defaultMessage='Forgot your password?' />*/}
                            {/*</Typography>*/}
                            {showReset ?
                                <Link
                                    className={`${classes.link} ${classes.asDiv} ${classes.info_head_reset}`}
                                    to="/signin"
                                >
                                    <FormattedMessage id='label.enter' defaultMessage='Enter'/>
                                </Link>
                                :
                                <Link
                                    className={`${classes.link} ${classes.asDiv} ${classes.info_head_reset}`}
                                    to="/reset"
                                >
                                    <FormattedMessage id='message.account.reset_password'
                                                      defaultMessage='Reset password'/>
                                </Link>
                            }
                        </form>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

SignIn.contextTypes = {
    updateUser: PropTypes.func,
    showNotify: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withRouter,
    withApollo,
    injectIntl,
)(SignIn);
